import React, { createContext, useContext, useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';
import { useUser } from './contextUser';

export const DesignContext = createContext();

const fadeInAnimation = keyframes`from {opacity: 0;} to {opacity: 1;}`;
const FadingComponent = styled.div`animation: ${fadeInAnimation} 0.3s ease-in-out;`;

export const DesignProvider = ({ children }) => {
  const {
    isSidebarExtended, setSidebarExtended,
    showProfileCard, setShowProfileCard,
    showUpgradeCard, setShowUpgradeCard,
    showEpssCard, setShowEpssCard,
  } = useUser();

  // Body layout measurements
  const mainBodyMarginAll = '.5rem';
  const navbarHeight = '70px';
  const extendedVal = '260px';
  const nonexterndedVal = '88px';
  const sidebarWidthVal = isSidebarExtended ? extendedVal: nonexterndedVal;
  const mainBodyHeight = `calc(100vh - ${navbarHeight} - (${mainBodyMarginAll} * 2))`;
  const mainBodyWidth = `calc(100vw - ${sidebarWidthVal} - (${mainBodyMarginAll} * 2))`;
  const viewHeight = `calc(${mainBodyHeight} - 100px)`;
  const [activeNav, setActiveNav] = useState(0);
  const [selectReportTab, setReportSelectedTab] = useState('Web Reports');

  // Toggles for sidebar and cards
  const toggleSidebar = () => setSidebarExtended(!isSidebarExtended);
  const toggleProfileCard = () => setShowProfileCard(!showProfileCard);
  const toggleUpgradeCard = () => setShowUpgradeCard(!showUpgradeCard);
  const toggleEpssCard = () => setShowEpssCard(!showEpssCard);

  const handleReportNav = (Dropdown) => {
    setActiveNav(9);
    setReportSelectedTab(Dropdown);
  }
  
  useEffect(() => {
    sessionStorage.setItem('activeSidebarIndex', activeNav);  
  }, [activeNav]);

  return (
    <DesignContext.Provider
      value={{
        FadingComponent,
        viewHeight,
        navbarHeight,
        mainBodyHeight,
        mainBodyWidth,
        sidebarWidthVal,
        extendedVal,
        nonexterndedVal,
        toggleSidebar,
        toggleProfileCard,
        toggleUpgradeCard,
        toggleEpssCard,
        activeNav, setActiveNav,
        handleReportNav,
        selectReportTab, setReportSelectedTab      
      }}>{children}
    </DesignContext.Provider>
  );
};


export const useDesign = () => {
  return useContext(DesignContext);
};
import React, { useState } from 'react';
import { useDesign } from '../context/contextDesign';

const buttonsData = [
  {
    title: 'Global Cyber Law',
    alt: 'Global Cyber Law',
    component: GlobalCyberLaw
  },{
    title: 'Indian Cyber Law',
    alt: 'Indian Cyber Law',
    component: IndianCyberLaw
  },{
    title: 'USA Cyber Law',
    alt: 'USA Cyber Law',
    component: USACyberLaw
  },{
    title: 'UK Cyber Law',
    alt: 'UK Cyber Law',
    component: UKCyberLaw
  },
];

const CyberLaw = ({ title }) => {
  const { viewHeight } = useDesign();
  const [selectedCard, setSelectedCard] = useState('Global Cyber Law');

  const handleClick = (card) => {
    setSelectedCard(card);
  };

  const cyberLawBtn = {
    height: 56,
    background: '#3A3756',
    color: '#f6f7f8',
    cursor: 'pointer',
  };

  return (
    <div className="h-100">
      <div className='p-1 pb-2'><h4>{title}</h4></div>
      <div className="d-flex justify-content-left">
        <div className='p-1 col-8'>
          <div className="mt-1 scrollbar-all-primary d-flex"
            style={{ height: viewHeight, paddingRight: 10 }}>
            <div className='h-auto col'>
              {!selectedCard && <GlobalCyberLaw />}
              {selectedCard && buttonsData.find(btn => btn.title === selectedCard).component()}
            </div>
          </div>
        </div>
        <div className="mt-1 col-3 px-3 mx-4">
          <div className="col-auto">
            {buttonsData.map(button => (
              <div key={button.title} className="py-1"
                onClick={() => handleClick(button.title)}>
                <div className="px-2 shadow-sm rounded-4 border-0 cursor-pointer
                  d-flex justify-content-left align-items-center"
                  style={{...cyberLawBtn, 
                    background: selectedCard === button.title ? '#3A3756' : '#1E1B2E'}}>
                  <div className="px-4">
                    <h6 className="card-title">{button.title}</h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CyberLaw;

function GlobalCyberLaw (){
  return(
    <div className="why-cyber-law">                
      <div className="">
        <h2>Why Did Cyber Law Become Necessary?</h2>
        <p>In the era of unprecedented technological advancement, the significance of cyber law has become paramount in ensuring a secure and ethical digital landscape. Cyber law, also known as information technology law or digital law, encompasses a comprehensive framework that addresses legal issues arising from the use of technology and the internet. It covers a spectrum of concerns, including data privacy, cybercrimes, intellectual property, and electronic commerce. As the digital realm continues to evolve, cyber law provides the legal infrastructure necessary to protect individuals, businesses, and governments from the challenges posed by cyber threats. Striking a delicate balance between fostering innovation and mitigating risks, cyber law plays a crucial role in shaping the ethical and legal standards that govern our increasingly interconnected and technology-dependent society.</p> 
        <p>As technology and electronic communications developed, it became clear that the legal framework existing at the dawn of the digital age would not be adequate to ensure a secure, fair, and inclusive internet for all users. The laws, regulations, and legal precedents that encompass what is now called cyber law seek to address:</p>
        <ul>
          <li><strong>Privacy and Data Protection</strong><br />As individuals and organizations began to share vast amounts of personal and sensitive information online, laws were required to protect privacy and regulate the collection, storage, and use of data.</li>
          <li><strong>Intellectual Property Protection</strong><br />The internet has made it easier to reproduce and distribute intellectual property such as music, movies, software, and written content. As a result, laws related to copyright infringement, piracy, and the protection of intellectual property online needed to be updated to reflect the new reality.</li>
          <li><strong>Cybersecurity and Cybercrime</strong><br />With the rise of cyberattacks, hacking, and online fraud, cyber law helped define and enforce rules related to cybersecurity and to prosecute cybercriminals.</li>
          <li><strong>E-Commerce and Online Contracts</strong><br />As online commerce grew, it became crucial to establish new rules for online contracts, electronic signatures, consumer protection, and dispute resolution for e-commerce transactions.</li>
          <li><strong>Freedom of Expression and Speech</strong><br />The internet expanded the ability of individuals to express their views, but it also raised questions about the limits of free speech and the regulation of hate speech, defamation, and other harmful online content.</li>
          <li><strong>Internet Governance</strong><br />Cyber law has been central to establishing frameworks for governing the internet, including domain name management, internet standards, and regulation of internet service providers.</li>
          <li><strong>Liability and Responsibility</strong><br />Cyber law has been instrumental in defining responsibilities and liabilities for various actors, including online platforms, content creators, and users.</li>
          <li><strong>Law Enforcement and International Cooperation</strong><br />Cyber law has had a role in facilitating cooperation among law enforcement agencies across borders to combat cybercrime and enforce cyber-related laws.</li>
        </ul>
        </div>
        <div className="">
          <h5 className="py-3 mt-5">
            <strong>Types of Cybercrime Addressed by Cyber Law</strong>
          </h5>
          <p>
            While the internet and other digital forms of communication have been beneficial in many ways, their emergence also offered criminals multiple new avenues for targeting and defrauding unsuspecting individuals, businesses, and organizations. Thus a significant percentage of the statutes and regulations related to cyber law address these various cybercrimes, including:
          </p>
          <ul>
            <li><strong>Phishing</strong><br />Phishing involves sending deceptive emails or messages that appear to be from legitimate sources but are designed to trick recipients into revealing personal information, such as login credentials or credit card numbers.</li>
            <li><strong>Ransomware</strong><br />Ransomware is malicious software that encrypts a victim's data, rendering it inaccessible until a ransom is paid to the attacker.</li>
            <li><strong>Identity Theft</strong><br />Cybercriminals steal personal information, such as Social Security numbers or credit card details, to impersonate victims or commit financial fraud. This information is often obtained through data breaches or phishing attacks.</li>
            <li><strong>Hacking</strong><br />Hackers illegally access computer systems or networks intending to steal data, disrupt operations, or carry out other malicious activities.</li>
            <li><strong>Cyberbullying</strong><br />Harassing, intimidating, cyberstalking or threatening others online, typically through social media or messaging platforms, are frequent targets of cyber law.</li>
            <li><strong>Online Scams</strong><br />Various online scams, such as the infamous "Nigerian Prince," deceive victims into sending money or providing personal information.</li>
            <li><strong>Distributed Denial of Service (DDoS) Attacks</strong><br />In a DDoS attack, multiple compromised computers flood a target website or network with traffic, causing it to become slow or unavailable. These attacks can disrupt online services and are often used to extort or sabotage.</li>
            <li><strong>Child Exploitation</strong><br />The production, distribution, or possession of child pornography is a serious cybercrime. Law enforcement agencies worldwide work to combat child exploitation on the internet.</li>
            <li><strong>Insider Threats</strong><br />Employees or individuals with access to sensitive information may misuse their privileges for personal gain or malicious purposes, such as stealing company secrets or customer data.</li>
            <li><strong>Online Drug Trafficking</strong><br />Some individuals use the dark web and cryptocurrencies to facilitate illegal drug trade and distribution.</li>
            <li><strong>Cyber Espionage</strong><br />State-sponsored or corporate entities may engage in cyber espionage to steal sensitive information, trade secrets, or intellectual property from rival organizations or governments.</li>
          </ul>
        </div>               
        <div className="">
          <h5 className="py-3 mt-5">
            <strong>International Cyber Law</strong>
          </h5>
          <p>
            Cyber law, cybercrime, and the regulation of cyber-related activities have also been addressed internationally through various means, including treaties, conventions, agreements, and collaborative efforts among countries and international organizations. Some of the most notable examples include:
          </p>
          <h6>
            <strong>The Budapest Convention on Cybercrime</strong>
          </h6>
          <p>
            Also known as the Convention on Cybercrime or the <a href="https://www.coe.int/en/web/cybercrime/the-budapest-convention" rel="noopener">Budapest Convention</a>, this treaty was adopted by the Council of Europe in 2001. It is also open for accession to non-European countries. It addresses various forms of cybercrime, including computer-related offenses, data breaches, and content-related offenses. Many countries have become parties to this convention, allowing for international cooperation in combating cybercrime.
          </p>
          <h6>
            <strong>African Union Convention on Cybersecurity and Personal Data Protection</strong>
          </h6>
          <p>
            Adopted in 2014, the Convention focuses on promoting cybersecurity and personal data protection in Africa. It aims to facilitate cooperation among African countries in addressing cybercrime and enhancing cyber resilience.
          </p>
          <h6>
            <strong>Commonwealth Model Law on Computer and Computer-Related Crime</strong>
          </h6>
          <p>
            The Commonwealth Secretariat developed the <a href="https://thecommonwealth.org/news/commonwealth-model-law-promises-co-ordinated-cybercrime-response" rel="noopener">Model Law</a> to provide guidance to member states in harmonizing their national legislation related to computer and computer-related crimes.
          </p>
          <h6>
            <strong>Organization of American States (OAS) Cybercrime Convention</strong>
          </h6>
          <p>
            While not a binding treaty like the Budapest Convention, the OAS has developed a framework for addressing cybercrime in the Americas, including the <a href="https://www.oas.org/en/sla/dlc/cyber-en/grupo-trabajo.asp" rel="noopener">Inter-American Cooperation Portal on Cybercrime</a>.
          </p>
        </div>
        <div className="">
          <h5 className="py-3 mt-5">
            <strong>The Elements of a Cybersecurity Policy</strong>
          </h5>
          <p>While they may serve as a deterrent, laws and regulations that seek to thwart cybercrime won't prevent every hack or ransomware attack. Therefore, it's essential that companies and organizations establish comprehensive cybersecurity policies for safeguarding digital assets and ensuring the confidentiality, integrity, and availability of all data and systems.</p>
          <p>At a minimum, a cybersecurity policy should cover:</p>
          <ul>
            <li><strong>Roles and Responsibilities</strong><br />Define key roles and responsibilities related to cybersecurity within the organization.</li>
            <li><strong>Access Controls</strong><br />Specify access control principles, strong password requirements, and multi-factor authentication requirements and methods.</li>
            <li><strong>Data Classification and Handling</strong><br />Establish a data classification system and describe how sensitive data should be handled, stored, and transmitted.</li>
            <li><strong>Incident Reporting and Response</strong><br />Outline the process for reporting cybersecurity incidents and the steps to be taken during incident response.</li>
            <li><strong>Security Awareness and Training</strong><br />Describe the organization's security awareness program and encourage employees to stay informed about cybersecurity threats and best practices.</li>
            <li><strong>Network Security</strong><br />Address network security measures, including firewalls, intrusion detection systems, and network segmentation.</li>
            <li><strong>Endpoint Security</strong><br />Define requirements for securing endpoint devices and antivirus/anti-malware software.</li>
            <li><strong>Data Backup and Recovery</strong><br />Outline data backup and recovery procedures, including regular backups and disaster recovery planning.</li>
            <li><strong>Vendor and Third-Party Security</strong><br />Establish guidelines for assessing and monitoring third-party cybersecurity practices and include relevant contract clauses.</li>
            <li><strong>Compliance and Legal Requirements</strong><br />Ensure alignment with relevant industry-specific regulations and legal requirements.</li>
            <li><strong>Monitoring and Auditing</strong><br />Describe the organization's monitoring and auditing procedures for detecting security incidents and policy violations.</li>
            <li><strong>Policy Review and Updates</strong><br />Specify a schedule for policy review and updates to ensure relevance and effectiveness.</li>
            <li><strong>Security Incident Communication</strong><br />Outline communication protocols for notifying employees, customers, and authorities in the event of a data breach or cybersecurity incident.</li>
          </ul>
      </div>
    </div>
  )
}

function IndianCyberLaw (){
  return (
    <div className='india-cyber-law'>
      <h2>India's Primary Cyber Law</h2>
      <p>
        India's primary cyber law is the <strong>Information Technology (IT) Act, 2000</strong>, which was amended in 2008. This law covers a wide range of activities related to the internet, digital communications, and electronic commerce. It addresses legal issues concerning online interactions and the internet. The IT Act provides a legal framework to manage cyber activities and deals with issues like digital signatures, cybercrime, and electronic governance. Some key provisions of the IT Act include:
      </p>
      <ul>
        <li><strong>Cybercrimes:</strong> The IT Act specifies various cybercrimes and penalties associated with them. These include hacking, identity theft, phishing, spreading viruses, and unauthorized access to computer systems.</li>
        <li><strong>Electronic Governance:</strong> The Act allows for the legal recognition of electronic records and digital signatures, facilitating e-governance and e-commerce.</li>
        <li><strong>Regulation of Certifying Authorities:</strong> The Act regulates entities that issue digital certificates used to authenticate the identity of individuals and organizations online.</li>
        <li><strong>Data Protection and Privacy:</strong> The IT Act includes provisions for protecting sensitive personal data. The 2008 amendments introduced Section 43A, which holds corporations responsible for implementing reasonable security practices to protect sensitive personal data they possess or handle.</li>
        <li><strong>Obscenity and Child Pornography:</strong> The Act contains provisions against publishing or transmitting obscene material, including child pornography, in electronic form.</li>
        <li><strong>Intermediary Liability:</strong> It outlines the due diligence requirements and liabilities of intermediaries, such as internet service providers (ISPs) and social media platforms, regarding unlawful content on their networks.</li>
        <li><strong>IT (Amendment) Act, 2008:</strong> This amendment introduced several changes, including enhanced penalties for cybercrimes, measures for privacy protection, and provisions for the establishment of a Computer Emergency Response Team (CERT-IN) to respond to cybersecurity incidents.</li>
      </ul>
      <p>
        It's important to note that the IT Act is subject to amendments and changes. For the most current information, referring to the official Gazette of India or consulting legal professionals who specialize in cyber law in India is advisable.
      </p>
    </div>
  );
}

function USACyberLaw (){
  return (
    <div className='usa-cyber-law'>
      <h2>Key U.S. Cyber Law Statutes</h2>
      <p>
        In the United States, multiple federal statutes have been enacted to address cybercrimes and a wide range of cyber-related activities, from computer hacking and fraud to online harassment and intellectual property theft. Some of these key laws Include:
      </p>
      <h6>
        <strong>Computer Fraud and Abuse Act (CFAA) (18 U.S.C. § 1030)</strong>
      </h6>
      <p>
        Enacted in 1986 as an amendment to the first federal computer fraud law, the CFAA initially addressed hacking but has since been amended multiple times to address a broader range of conduct. The Act prohibits intentionally accessing a computer without authorization or in excess of authorization. However, because the CFAA fails to define precisely what "without authorization" means, civil libertarians assert that it could be used to criminalize nearly every aspect of computer activity, even those that most people consider innocuous.
      </p>
      <h6>
        <strong>Identity Theft and Assumption Deterrence Act (18 U.S.C. § 1028)</strong>
      </h6>
      <p>
        Enacted in 1998, the Identity Theft and Assumption Deterrence Act prohibits anyone from misusing another person's identifying information, whether personal or financial, including:
      </p>
      <ul>
        <li>Social Security numbers</li>
        <li>Credit card information</li>
        <li>Bank account information</li>
        <li>PIN numbers</li>
        <li>Driver's license numbers</li>
        <li>Birth or death certificates</li>
      </ul>
      <p>
        In 2004, the Act was amended to establish the offense of aggravated identity theft, which involves using another's information in connection with certain federal crimes or in relation to an act of terrorism.
      </p>
      <h6>
        <strong>Digital Millennium Copyright Act (DMCA)</strong>
      </h6>
      <p>
        Passed in 1998, the DMCA amended U.S. copyright law to address the relationship between copyright and the internet by:
      </p>
      <ol>
        <li>Establishing protections for online service providers in the event their users engage in copyright infringement.</li>
        <li>Encouraging copyright owners to give greater access to their works in digital formats by providing them with legal protections against unauthorized access.</li>
        <li>Making it unlawful to provide false copyright management information or to remove or alter that type of information in certain circumstances.</li>
      </ol>
      <h6>
        <strong>Child Online Privacy Protection Act (COPPA)</strong>
      </h6>
      <p>
        COPPA aims to protect the privacy of children under the age of 13 by requiring that website owners:
      </p>
      <ul>
        <li>Incorporate a detailed privacy policy detailing information collected from its users.</li>
        <li>Acquire verifiable parental consent before collection of personal information from a child under the age of 13.</li>
        <li>Disclose to parents any information collected on their children by the website.</li>
        <li>Allow parents to revoke consent and have information deleted at any time.</li>
        <li>Limit collection of personal information when a child participates in online games and contests.</li>
        <li>Protect the confidentiality, security, and integrity of any personal information collected online from children.</li>
      </ul>
    </div>
  );
}

function UKCyberLaw (){
  return(
    <div className='uk-cyber-law'>
      <h2>UK Cyber Law Overview</h2>
      <p>
        In the United Kingdom, cyber law encompasses various statutes and regulations designed to address issues related to information technology, cybercrime, data protection, and digital transactions. UK cyber law aims at protecting individuals, businesses, and government entities against cyber threats while ensuring the secure and lawful use of digital technologies. Key pieces of legislation in this domain include:
      </p>
      <ul>
        <li><strong>Computer Misuse Act 1990:</strong> This Act addresses cybercrime by making unauthorized access to computer systems, intent to commit further offenses, and unauthorized modification of computer material illegal. It has been updated to reflect technological advancements.</li>
        <li><strong>Data Protection Act 2018:</strong> This Act controls the use of personal information by various entities and is the UK's implementation of the GDPR. It outlines a comprehensive data protection framework, detailing principles, rights, obligations, and penalties for breaches.</li>
        <li><strong>The Privacy and Electronic Communications (EC Directive) Regulations 2003 (PECR):</strong> PECR complements the Data Protection Act by covering the security of public electronic communications services and privacy in electronic communications, including the rules on marketing and cookies.</li>
        <li><strong>Network and Information Systems Regulations 2018 (NIS Regulations):</strong> Aimed at improving national cybersecurity, this legislation mandates that certain service operators and digital service providers manage risks and report significant incidents.</li>
        <li><strong>Investigatory Powers Act 2016:</strong> Known as the "Snoopers' Charter," it outlines the legal framework for surveillance, communication interception, and data collection by UK intelligence and law enforcement for national security and crime fighting.</li>
        <li><strong>Cyber Security Information Sharing Partnership (CiSP):</strong> Though not a law, CiSP is a governmental initiative for sharing information on cyber threats and vulnerabilities to protect UK businesses.</li>
      </ul>
      <p>
        These laws and initiatives form the backbone of UK's approach to cyber law, addressing issues from cybercrime to national security in the digital age. Due to the fast pace of technological and cyber threat evolution, UK cyber law is under constant review and amendment. For the latest information, it is recommended to consult official government sources or legal professionals specializing in this area.
      </p>
    </div>
  )
}

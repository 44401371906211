import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/contextAuth';
import { useUser } from '../../context/contextUser';
import { useDesign } from '../../context/contextDesign';
import { Link } from 'react-router-dom';
import LogoImg from '../asset/img/logo/Save-Logo-White-2.png'
import menuItems from '../../config/menuItems';

const SideNavbar=()=>{
  const { user, upgradePlan } = useAuth();
  const { sidebarWidthVal, } = useDesign();
  const { isSidebarExtended } = useUser();
  //console.log(upgradePlan);
  return(
    <nav className="navbar navbar-dark">
      <div className="sidebar" style={{
          width: sidebarWidthVal,
          height: '100vh',
          transition: 'all 0.2s',
        }}>
        <div className="sidebarExtended d-flex flex-column 
          p-0 h-100 w-auto bg-transparent position-absolute"
          style={{ transition: 'all 0.2s' }}>
          <SidebarLogo />
          <SidebarItems />
          <div className="sidebarPseudoFooter z-2"
            style={{
              marginBottom: isSidebarExtended? !upgradePlan? 260:86:60,
              bottom: isSidebarExtended? !upgradePlan? 0:24:-400,
              transition: isSidebarExtended ?'all 0.7s':'all 0.0s',
            }}/>
        </div>
        <SidebarFooter />
      </div>
    </nav>
  )
}

export default SideNavbar;


const SidebarLogo =()=>{  
  const { isSidebarExtended } = useUser();
  const logoStyleCropped = {width: '72px', transition: 'all 0.2s', marginRight: -9,};
  const logoStyle = {width: '126px', transition: 'all 0.2s'};
  return(
    <div className={`logo d-flex justify-content-center align-items-center mx-auto mb-2
      ${isSidebarExtended?'mt-4':'mt-2'}`}
      style={{
        height: isSidebarExtended ? '23vh':'60px',
        transition: 'all 0.3s',
      }}>
      <a href='/'>
        <img className='h-100' src={LogoImg} alt='{SAVE}'
          style={isSidebarExtended ? logoStyle : logoStyleCropped} />
      </a>
    </div>
  )
}
const SidebarItems =()=>{
  const { upgradePlan } = useAuth();
  const { activeNav, setActiveNav, sidebarWidthVal } = useDesign();
  const { isSidebarExtended, setUrlVulTableToggle  } = useUser();
  const handleItemClick = (itemId) => {
    setActiveNav(itemId);
  };
  const [isVisible, setIsVisible] = useState(true);
  
  return(
    <div
      className="sidebarExtendedlist nav scrollbar-primary d-inline p-2"
      style={{
        width: isSidebarExtended? `calc(${sidebarWidthVal} + 7px)`:96,
        transition: 'all 0.2s' }}>
      {menuItems.map((item) => (
        <div key={item.id} className="nav-item" onClick={() => handleItemClick(item.id)}>
          <Link
            to={item.path}
            onClick={() => {setUrlVulTableToggle(false)}}
            className={`nav-link text-white my-2 d-flex rounded-3 ${
              activeNav === item.id ? 'active' : ''
            }`}
            style={{
              background: activeNav === item.id ? 'rgb(58, 37, 78)' : '',
              height: isSidebarExtended ? '50px' : '60px',
              transition: 'all 0.2s',
            }}>
            <div className="d-flex align-items-center">
              <i className={`${
                  activeNav === item.id ? item.activeIcon : item.icon
                } my-2`}
                style={{
                  margin: 'auto',
                  marginRight: isSidebarExtended ? '12px' : '6px',
                  marginLeft: isSidebarExtended ? '10px' : '6px',
                  fontSize: isSidebarExtended ? '16px' : '20px',
                  transition: 'all 0.2s',
                }}/>
              <div
                // className={`sidebarTextitems ${isVisible ? 'fade-in' : 'fade-out'}`}
                style={{
                  marginLeft: isSidebarExtended ? '0px' : '-230px',
                  display: isVisible ? 'block' : 'none',
                  fontWeight: activeNav === item.id ? '700': 'normal',
                  transition: 'margin-left 0.2s',
                  zIndex: isSidebarExtended ? 0 : -2,
                }}>{item.title}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

const SidebarFooter =()=>{
  const { upgradePlan } = useAuth();
  const { extendedVal } = useDesign();
  const { isSidebarExtended } = useUser();
  return(
    <>
      <div className={`sidebarExtended position-absolute p-1 mx-2 z-1`}
        style={{
          borderRadius: '20px',
          border: '1px solid #444444',
          backgroundColor: '#100021',
          bottom: isSidebarExtended? !upgradePlan? 20:30:-400,
          width: isSidebarExtended?`calc(${extendedVal} - 10px)`:extendedVal,
          height: 'auto',
          transition: isSidebarExtended ?'all 0.7s':'all 0.0s',
        }}>
        {!upgradePlan && <SubscriptionCard />}
        <TermsConditions />
      </div>
    </>
  );
}
const SubscriptionCard = () => {
  const { toggleUpgradeCard } = useDesign();
  return (
    <div className='subscription-card'>
      <div className="card rounded-4">
        <div className="card-body text-center text-white">
          <div className="card-title fs-2">Pro Access</div>
          <p className="card-text">
            Upgrade the plan to get access to unlimited reports
          </p>      
          <a href="/" className="btn btn-primary upgrade-btn border-0 mt-2 w-75">
            Upgrade
          </a>
        </div>
      </div>
    </div>
  );
}
const TermsConditions = () => {
  return(
    <div className='h-100'>
      <div className='py-1 text-center'>
        <a href='/Terms-Conditions' className='text-white'
          style={{fontSize: '13px', fontWeight: 500}}>
          Terms & Conditions
        </a>
      </div>
    </div>
  )
}
import React, { useContext, useRef, useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../context/contextAuth';
import { useDesign } from '../../context/contextDesign';
import { useUser } from '../../context/contextUser';
import { getImageUrl } from '../../api/users';
import { generateProfilePic } from '../../utils/generateProfilePic';
import DefaultProfileImg from '../asset/img/default-profile.avif';

const HWVal = 40;

const Profile = () => {
  const { user, signout } = useAuth();
  const { toggleProfileCard, setActiveNav } = useDesign();
  const { profilePicture } = useUser();
  const handleSignout = () => signout();

  return (
    <>
      <div className="dropdown">
        <div
          className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false" 
          style={{ fontSize: 12 }}>
          <img
            src={profilePicture}
            alt="Profile"
            width={HWVal}
            height={HWVal}
            className="rounded-circle"
            style={{ border: '2px solid #fff' }}
          />
        </div>
        <div
          className="dropdown-menu dropdown-menu-dark shadow rounded-3 border-0"
          style={{ 
            background: 'rgb(52 66 102)', 
            textAlign: "left",
            position: 'absolute', 
            top: 40,  
            left: -130, 
            width: 200, 
            minWidth: 0, 
            fontSize: 14 
          }}>
          <div>
            <span>
              <div className="px-3" style={{ fontSize: 16 }}>{`${user?.first_name} ${user?.last_name}`}</div>
              <div className="px-3 disabled" style={{ fontSize: 10 }}>{`@${user?.username}`}</div>
            </span>
          </div>
          <div><hr className="dropdown-divider" /></div>
          <div><span className="px-3 dropdown-item" onClick={toggleProfileCard}>Settings</span></div>
          <div><hr className="dropdown-divider" /></div>
          <div>
            <span className="px-3 dropdown-item" 
              onClick={() => {
                handleSignout();
                setActiveNav(0);
              }}>Sign out
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

import React, { useRef, useEffect, useState } from 'react';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
// import HistoricalData from './components/Cards/HistoricalData';
// import RiskExposurelist from './components/Cards/RiskExposureList-Ip';
import Vulnerabilities from './components/Cards/c-item-0-vulnerabilities';
import CriticalVul from './components/Cards/c-item-1-critical-vulnerability';
import SaveScore from './components/Cards/c-save-score';
import SecurityAudit from './components/Cards/c-security-audit-result';
import { ResponsiveContainer, 
  BarChart, Bar, XAxis, YAxis, Tooltip,
  PieChart, Pie, Legend, CartesianGrid } from 'recharts';

const Dashboard =({title, Loader2})=>{
  const { viewHeight,mainBodyHeight, mainBodyWidth } = useDesign();
  const {
    urlAssets, urlVulTableAll,
    ipAssets, ipVulTableAll,
    cloudAssets, cloudVulTableAll
  } = useUser ();
  
  const timeoutRef = useRef(null);
  const [loading, setLoading] = useState(null);
  const [webTVulScore, setWebTVulScore] = useState(0);
  const [webCVulScore, setWebCVulScore] = useState(0);
  const [webSaveScore, setWebSaveScore] = useState(0);
  const [networkTVulScore, setNetworkTVulScore] = useState(0);
  const [networkCVulScore, setNetworkCVulScore] = useState(0);
  const [networkSaveScore, setNetworkSaveScore] = useState(0);
  const [cloudTVulScore, setCloudTVulScore] = useState(0);
  const [cloudCVulScore, setCloudCVulScore] = useState(0);
  const [cloudSaveScore, setCloudSaveScore] = useState(0);
  
  const calculateUrlAvgSS = (Assets) => {
    if (!Array.isArray(Assets) || Assets.length === 0) return 0;    
    const totalSaveScore = Assets.reduce((total, asset) => {
      return total + (asset.save_score || 0);
    }, 0);    
    return totalSaveScore / Assets.length;
  };
  
  const calculateIpAvgSS = (Assets) => {
    if (!Array.isArray(Assets) || Assets.length === 0) return 0;
    const totalSaveScore = Assets.reduce((total, asset) => {
      return total + (asset.save_score || 0);
    }, 0);
    return totalSaveScore / Assets.length;
  };
  
  const calculateCloudAvgSS = (Assets) => {
    if (!Assets || Assets.length === 0) return 0;
    const totalSaveScore = Assets.reduce((total, asset) => {
      return total + (asset.save_score || 0);
    }, 0);
    return totalSaveScore / Assets.length;
  };
  
  // Web Vulnerabilities
  const wTV = urlVulTableAll ? urlVulTableAll.length : 0;  // Total Web Vulnerabilities
  const wCV = urlVulTableAll ? urlVulTableAll.filter(vuln => vuln.level >= 9).length : 0;  // Critical Web Vulnerabilities
  const wHC = urlVulTableAll ? urlVulTableAll.filter(vuln => vuln.level >= 7 && vuln.level < 9).length : 0;  // High Web Vulnerabilities
  const wMC = urlVulTableAll ? urlVulTableAll.filter(vuln => vuln.level >= 4 && vuln.level < 7).length : 0;  // Medium Web Vulnerabilities
  const wLC = urlVulTableAll ? urlVulTableAll.filter(vuln => vuln.level < 4).length : 0;  // Low Web Vulnerabilities
  const wSS = Math.round(calculateUrlAvgSS(urlAssets));  // Web Save Score
  // console.log(urlAssets);
  
  // IP Vulnerabilities
  const nTV = ipVulTableAll ? ipVulTableAll.length : 0;  // Total IP Vulnerabilities
  const nCV = ipVulTableAll ? ipVulTableAll.filter(vuln => vuln.level >= 9).length : 0;  // Critical IP Vulnerabilities
  const nHC = ipVulTableAll ? ipVulTableAll.filter(vuln => vuln.level >= 7 && vuln.level < 9).length : 0;  // High IP Vulnerabilities
  const nMC = ipVulTableAll ? ipVulTableAll.filter(vuln => vuln.level >= 4 && vuln.level < 7).length : 0;  // Medium IP Vulnerabilities
  const nLC = ipVulTableAll ? ipVulTableAll.filter(vuln => vuln.level < 4).length : 0;  // Low IP Vulnerabilities
  const nSS = Math.round(calculateIpAvgSS(ipAssets));  // IP Save Score
  
  // Cloud Vulnerabilities
  const cTV = cloudVulTableAll ? cloudVulTableAll.length : 0;  // Total Cloud Vulnerabilities
  const cCV = cloudVulTableAll ? cloudVulTableAll.filter(vuln => vuln.level >= 9).length : 0;  // Critical Cloud Vulnerabilities
  const cHC = cloudVulTableAll ? cloudVulTableAll.filter(vuln => vuln.level >= 7 && vuln.level < 9).length : 0;  // High Cloud Vulnerabilities
  const cMC = cloudVulTableAll ? cloudVulTableAll.filter(vuln => vuln.level >= 4 && vuln.level < 7).length : 0;  // Medium Cloud Vulnerabilities
  const cLC = cloudVulTableAll ? cloudVulTableAll.filter(vuln => vuln.level < 4).length : 0;  // Low Cloud Vulnerabilities
  const cSS = Math.round(calculateCloudAvgSS(cloudAssets));  // Cloud Save Score

  // Total Vulnerability Scores
  const TVulScore = wTV + nTV + cTV;    // Total Vulnerability Score (Web + IP + Cloud)  
  const THVulScore = wHC + nHC + cHC;   // Total High Vulnerability Score (Web + IP + Cloud)
  const TMVulScore = wMC + nMC + cMC;   // Total Medium Vulnerability Score (Web + IP + Cloud)
  const TLVulScore = wLC + nLC + cLC;   // Total Low Vulnerability Score (Web + IP + Cloud)
  const TCVulScore = wCV + nCV + cCV;   // Total Critical Vulnerability Score (Web + IP + Cloud)

  // Total Save Score
  const TSaveScore = Math.round((wSS + nSS + cSS) / ((urlAssets?1:0)+(ipAssets?1:0)+(cloudAssets?1:0)));
  // // console.log('wSS:', wSS);
  // // console.log('nSS:', nSS);
  // // console.log('cSS:', cSS);
  // // console.log('TSaveScore-',TSaveScore);
  const AuditResult = '';

  const pieData = [
    {
      name: "Critical",
      value: TCVulScore,  // Use the total critical vulnerability score
      fill: "#6d2d9d"     // Red for critical vulnerabilities
    },
    {
      name: "High",
      value: THVulScore,  // Use the total high vulnerability score
      fill: "#fc0000"     // Orange for high vulnerabilities
    },
    {
      name: "Medium",
      value: TMVulScore, // Use the total medium vulnerability score
      fill: "#ffc000"     // Yellow for medium vulnerabilities
    },
    {
      name: "Low",
      value: TLVulScore, // Use the total low vulnerability score
      fill: "#92d14f"     // Grey for low vulnerabilities
    }
  ];

  const webData = [
    { name: 'Critical', vulnerabilities: wCV, fill: "#6d2d9d" },
    { name: 'High', vulnerabilities: wHC, fill: "#fc0000" },
    { name: 'Medium', vulnerabilities: wMC, fill: "#ffc000" },
    { name: 'Low', vulnerabilities: wLC, fill: "#92d14f" },
    { name: 'Save Score', vulnerabilities: wSS, fill: "#ffffff" },
  ].reverse();

  const networkData = [
    { name: 'Critical', vulnerabilities: wCV },
    { name: 'High', vulnerabilities: wHC },
    { name: 'Medium', vulnerabilities: wMC },
    { name: 'Low', vulnerabilities: wLC },
    { name: 'Save Score', vulnerabilities: wSS },
  ].reverse();
  
  const cloudData = [
    { name: 'Critical', vulnerabilities: cCV },
    { name: 'High', vulnerabilities: cHC },
    { name: 'Medium', vulnerabilities: cMC },
    { name: 'Low', vulnerabilities: cLC },
    { name: 'Save Score', vulnerabilities: cSS },
  ].reverse();

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }setLoading(true);

    try{
      setWebTVulScore(wTV);
      setWebCVulScore(wCV);
      setWebSaveScore(wSS);
      setNetworkTVulScore(nTV);
      setNetworkCVulScore(nCV);
      setNetworkSaveScore(nSS);
      setCloudTVulScore(cTV);
      setCloudCVulScore(cCV);
      setCloudSaveScore(cSS);
    }catch(error){
      // console.log(error);
    }finally{
      setLoading(false);
    }

    timeoutRef.current = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  });

  const props={ 
    title, mainBodyHeight, mainBodyWidth, viewHeight, 
    webTVulScore, webCVulScore, webSaveScore,
    networkTVulScore, networkCVulScore, networkSaveScore,
    cloudTVulScore, cloudCVulScore, cloudSaveScore,
    TVulScore, TCVulScore, TSaveScore, AuditResult,
    pieData, webData, networkData, cloudData
  };
  
  return(
    <>
      {loading ? (<Loader2 />):(
        <div className='h-100 default-dashboard'>
          <div className='p-1'>
            <div className='h4'>{title}</div>
          </div>
          <div className='scrollbar-all-primary d-flex flex-row'
            style={{height: viewHeight}}>
            <DashboardView {...props} />
            <CardView {...props} />
          </div>
        </div>
      )}      
    </>
  )
}
export default Dashboard;

const DashboardView = ({
  TVulScore, TCVulScore, TSaveScore, AuditResult, pieData
}) =>{
  return(
    <div>
      <div className='d-flex flex-row'> 
        <div className='d-flex flex-column'>
          <Vulnerabilities setScore={TVulScore}/>
          <CriticalVul setScore={TCVulScore}/>
        </div>
        <div className='d-flex flex-column'>
        <SaveScore setScore={TSaveScore}/>
        <SecurityAudit setText={AuditResult}/>
        </div>
      </div>
      <PieChartView pieData={pieData} />
    </div>
  );
};

const CardView = ({ 
  webTVulScore, webCVulScore, webSaveScore,
  networkTVulScore, networkCVulScore, networkSaveScore,
  cloudTVulScore, cloudCVulScore, cloudSaveScore,
  webData, networkData, cloudData
  }) => {
  const cardStyle = { height: 270, width: 400 };
  const RoundedBar = (props) => {
    const { x, y, width, height, fill } = props;
    return (
      <rect x={x} y={y}
        width={width} height={height} fill={fill}
        rx={3} // Set the border radius here
      />
    );
  };

  return(
    <>
      <div className='d-flex flex-column overall-card'>
        <div className='mb-2'>
          <div className='card p-5 rounded-4 text-white'
            style={cardStyle}>
            <div className='d-flex flex-column'>
              <div className='w-100'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <div className='h3' height={50}>Web Score</div>
                  <div height={50}>
                    <ResponsiveContainer width={70}>
                      <BarChart data={webData}
                        margin={{ right: 0, left: 0, bottom:10, top: 0 }}>
                        <Bar 
                          dataKey="vulnerabilities"
                          barSize={16}
                          shape={<RoundedBar />}
                        />
                      </BarChart>      
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>             
              <div className='py-2'/>
              <div className='w-100'>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='h5 pb-1 fw-light'>Vulnerability</div>
                  <div className='h5'>{webTVulScore}</div>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='h5 pb-1 fw-light'>Critical Vulnerability </div>
                  <div className='h5'>{webCVulScore}</div>
                </div>
                <div className='d-flex flex-row justify-content-between'>         
                  <div className='h5 pb-1 fw-light'>Save Score</div>
                  <div className='h5'>{webSaveScore}</div>
                </div>
              </div>              
            </div>
          </div>
        </div>
        <div className='mb-2'>
          <div className='card p-5 rounded-4 text-white'
            style={cardStyle}>
            <div className='d-flex flex-column'>
              <div className='w-100'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <div className='h3' height={50}>Network Score</div>
                  <div height={50}>
                    <ResponsiveContainer width={70}>
                      <BarChart data={webData}
                        margin={{ right: 0, left: 0, bottom:10, top: 0 }}>
                        <Bar 
                          dataKey="vulnerabilities"
                          barSize={16}
                          shape={<RoundedBar />}
                        />
                      </BarChart>      
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>             
              <div className='py-2'/>
              <div className='w-100'>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='h5 pb-1 fw-light'>Vulnerability</div>
                  <div className='h5'>{networkTVulScore}</div>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='h5 pb-1 fw-light'>Critical Vulnerability </div>
                  <div className='h5'>{networkCVulScore}</div>
                </div>
                <div className='d-flex flex-row justify-content-between'>         
                  <div className='h5 pb-1 fw-light'>Save Score</div>
                  <div className='h5'>{networkSaveScore}</div>
                </div>
              </div>              
            </div>
          </div>
        </div>
        <div className='mb-2'>
          <div className='card p-5 rounded-4 text-white'
            style={cardStyle}>
            <div className='d-flex flex-column'>
              <div className='w-100'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <div className='h3' height={50}>Cloud Score</div>
                  <div height={50}>
                    <ResponsiveContainer width={70}>
                      <BarChart data={webData}
                        margin={{ right: 0, left: 0, bottom:10, top: 0 }}>
                        <Bar 
                          dataKey="vulnerabilities"
                          barSize={16}
                          shape={<RoundedBar />}
                        />
                      </BarChart>      
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>             
              <div className='py-2'/>
              <div className='w-100'>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='h5 pb-1 fw-light'>Vulnerability</div>
                  <div className='h5'>{cloudTVulScore}</div>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='h5 pb-1 fw-light'>Critical Vulnerability </div>
                  <div className='h5'>{cloudCVulScore}</div>
                </div>
                <div className='d-flex flex-row justify-content-between'>         
                  <div className='h5 pb-1 fw-light'>Save Score</div>
                  <div className='h5'>{cloudSaveScore}</div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PieChartView = ({pieData}) => {
  const renderCustomizedLabel = ({ name, value }) => `${name}: ${value}`;
  const filteredPieData = pieData.filter(data => data.value > 0);
  const cardHeight = 360;
  return(
    <div className='pe-2'>
      <div className='card rounded-4'
        style={{ fontSize: 16, height: 400 }}>
        <div className='card-body'>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={pieData} dataKey="value" 
                nameKey="name" cx="50%" cy="50%" 
                innerRadius={80} outerRadius={100}
                label={renderCustomizedLabel}
              />
              <Legend
                iconSize={10}
                width={120}
                height={140}       
                layout='vertical'
                verticalAlign='top'
                align="right"
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
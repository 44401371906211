import React, { useState } from 'react';
import { useAuth } from '../context/contextAuth';
import { useDesign } from '../context/contextDesign';
import { postMalUrl } from "../api/api-post";
import Loader2 from "./components/Loaders/Loader-2/Loader-2";

const MalwareScanner =({title})=>{
  const { viewHeight, mainBodyHeight, mainBodyWidth } = useDesign(); 
  const [malwareScanGraphView, setMalwareScannerGraphView] = useState(false);
  const props={
    title, mainBodyHeight, mainBodyWidth,
    viewHeight, setMalwareScannerGraphView
  };

  return(
    <>
      <div className="h-100">
        <div className='p-2'></div>
        {/* <div className='p-1'><h4>{title}</h4></div> */}
        {!malwareScanGraphView && (
          <div className='p-1 col-12'>
            <div className="mt-1" style={{height: viewHeight, paddingRight: 10}}>
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <MalwareScanCard {...props}/>
              </div>
            </div>
          </div>
        )}
        {malwareScanGraphView && (
          <MalwareScanHistoryView {...props}/>
        )}
      </div>     
    </>
  )
}

export default MalwareScanner;

function MalwareScanCard() {
  const { userId } = useAuth();
  const { viewHeight } = useDesign(); 
  const [activeTab, setActiveTab] = useState('File');
  const [activeTabResult, setActiveTabResult] = useState('Detection');
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(null);
  const [urlInput, setUrlInput] = useState('');
  
  
  const tabColor = '#344266';
  const fontStyle = { fontSize: 15, fontWeight: 500 };

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  const handleClickResult = (tab) => {
    setActiveTabResult(tab);
  }

  const handleUrlSubmit = async () => {
    if (activeTab === 'Url' && urlInput) {
      setLoading(true);
      try {
        const data = await postMalUrl(userId, urlInput);
        setShowData(data);
        console.log(data);
        setUrlInput('');  // Clear the URL input after submission
      } catch (error) {
        console.error('Error during URL submission:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const props = {
    handleUrlSubmit, fontStyle, tabColor,
    showData, urlInput, setUrlInput
  };
  const tabStyle = {
    width: 160,
    height: 50,
    cursor: 'pointer',
    borderRadius: 0,
    border: 0,
    outline: 0,
    fontWeight: 600,
  };
  return (
    <>
      {loading ? (<Loader2/>):(
        <>
          {!showData ? (
            <>
              <div className="text-center">
                <h1 style={{
                    fontFamily: '"Jersey 20 Charted", sans-serif',
                    fontSize: 60,
                    fontWeight: 400,
                    fontStyle: "normal",
                  }}>MALWARE SCANNER
                </h1>
                <h6>powered by <b>VIRUSTOTAL</b></h6>
              </div>
              <div className="py-3 d-flex justify-content-center align-items-center">
                <button className="py-3 px-4 btn"
                  style={activeTab === 'File'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
                  onClick={() => handleClick('File')}>File
                </button>
                <button className="py-3 px-4 btn"
                  style={activeTab === 'Url'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
                  onClick={() => handleClick('Url')}>Url
                </button>
                <button
                  className="py-3 px-4 btn"
                  style={activeTab === 'Search'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
                  onClick={() => handleClick('Search')}>Search
                </button>
              </div>
              <div className="d-flex justify-content-center flex-column" width="480px">
                <div style={{ height: 180 }}>
                  {activeTab === 'File' && (
                    <div className="d-flex flex-column justify-content-center fade-in">
                      <div className="d-flex justify-content-center">
                        <i className="bi bi-file-earmark-break-fill" style={{ fontSize: '4rem', color: '#6c757d' }} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="py-2 px-4 btn malware-scanner-btn text-white" 
                          style={fontStyle}>Choose File
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === 'Url' && (
                    <div className="d-flex justify-content-center flex-column fade-in">        
                      <div className="d-flex justify-content-center">
                        <i className="bi bi-link-45deg" style={{ fontSize: '4rem', color: '#6c757d' }} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="col-6">
                          <input
                            style={fontStyle}
                            type="text"
                            className="form-control text-center"
                            placeholder="Scan a URL"
                            value={urlInput}
                            onChange={(e) => setUrlInput(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <button className="py-2 px-4 btn malware-scanner-btn text-white" 
                          onClick={handleUrlSubmit}>
                          Submit URL
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === 'Search' && (
                    <div className="d-flex justify-content-center flex-column fade-in">
                      <div className="d-flex justify-content-center">
                        <i className="bi bi-search" style={{ fontSize: '4rem', color: '#6c757d' }} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="col-6">
                          <input
                            style={fontStyle}
                            type="text"
                            className="form-control text-center"
                            placeholder="Search for Domain, IP Address, URL"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <button className="py-2 px-4 btn malware-scanner-btn text-white" 
                          onClick={handleUrlSubmit}>
                          Submit URL
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center text-center pt-2 fade-in">
                  <div className="col-6" style={{ fontSize: '12px', fontWeight: 600 }}>
                    <br />
                    By submitting data above, you are agreeing to our <a href="/Terms-Conditions">Terms of Service</a> and <a href="/Privacy-Policy">Privacy</a> Notice, and to the sharing of your Sample submission with the security community. Please do not submit any personal information; VirusTotal is not responsible for the contents of your submission. Learn more.
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="w-100 d-flex justify-content-left px-5">        
              <div className="col-10">
                <div><HeaderView {...props}/></div>
                <div className="px-4">
                  <div className="h3 my-3">Scan Results:</div>
                  <div className="d-flex justify-content-left align-items-center">
                    <button className="py-1 px-4 btn"
                      style={activeTabResult === 'Detection'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
                      onClick={() => handleClickResult('Detection')}>Detection
                    </button>
                    <button className="py-1 px-4 btn"
                      style={activeTabResult === 'Details'? { ...tabStyle, boxShadow: `inset 0 -3px 0 ${tabColor}`, color: `${tabColor}` }: tabStyle}
                      onClick={() => handleClickResult('Details')}>Details
                    </button>
                  </div>
                  <div className="d-flex justify-content-center flex-column">                
                    <div className="scrollbar-all-primary" style={{ height: `calc(${viewHeight} - 240px`}}>
                      {activeTabResult === 'Detection' && <DetectionView {...props} />}
                      {activeTabResult === 'Details' && <DetailsView {...props} />}
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          )}
        </>
      )}
    </>
  );
}

function MalwareScanHistoryView(){
  return(
    <>
      
    </>
  )
}

function HeaderView({showData}) {
  const url = showData.data.attributes.url;
  const status_code = showData.data.attributes.last_http_response_code;
  const content_type = showData.data.attributes.last_http_response_headers['Content-Type'];
  const date_of_last_scan_unix = showData.data.attributes.last_analysis_date;
  const date_of_last_scan = convertToRelativeTime(date_of_last_scan_unix);

  console.log(showData);
  return(
    <>
      <div className="d-flex flex-row align-items-center py-3 px-4 rounded-4 text-white" style={{background: '#344266'}}>
        <div className="col">
          <span><i className="bi bi-link-45deg"/></span> {url}</div>
        <div className="d-flex flex-row">
          <div>Status<br/>
            <b>
              <i className={`bi bi-${status_code === 200 ? 'check-circle-fill' : 'x-circle-fill'} me-1`} 
                style={{color: status_code === 200?'#00a73d': '#fff'}}
              />{status_code}</b>
            </div>
          <div className='mx-3' style={{borderLeft: 'solid 2px #fff', height: 50, width: 2}}/>
          <div>Content type<br/><b>{content_type}</b></div>
          <div className='mx-3' style={{borderLeft: 'solid 2px #fff', height: 50, width: 2}}/>
          <div>Data last scan<br/><b>{date_of_last_scan}</b></div>
        </div>
      </div>
    </>
  );
};

function DetectionView({showData}){  
  const scan_results = showData.data.attributes.last_analysis_results;
  return(
    <>     
      <div className="">
        <table border="0" cellPadding="10" cellSpacing="0">          
          <tr>
            <th>Engine Name</th>
            <th>Method</th>
            <th>Category</th>
            <th><span className="px-1"/>Result</th>
          </tr>     
          {Object.keys(scan_results).map((key, index) => (
            <tr key={index}>
              <td>{scan_results[key].engine_name}</td>
              <td>{scan_results[key].method}</td>
              <td>{scan_results[key].category}</td>
              <td>
                <span className="px-1">
                  <i className={`bi bi-${scan_results[key].result === 'clean' ? 'check-circle-fill' : 'question-lg'}`}
                    style={{color: scan_results[key].result === 'clean' ? '#00a73d': '#373b3e',}}
                  />
                </span>
                {scan_results[key].result}
              </td>              
            </tr>
          ))}          
        </table>
      </div>
    </>
  )
}

function DetailsView({showData}){
  const url = showData.data.attributes.url;
  const status_code = showData.data.attributes.last_http_response_code;
  // const content_type = showData.attributes.last_http_response_headers[Content-Type];
  const date_of_last_scan = showData.data.attributes.last_analysis_date;
  return(
    <>
      <div className="d-flex flex-column">
        <div className="col"><span><i className="bi bi-link-45deg"></i></span> {url}</div>
        <div className="col">
          <div>Status<br/>{status_code}</div>
          <div>|</div>
          {/* <div>Content type<br/>{content_type}</div> */}
          <div>|</div>
          <div>Data last scan<br/>{date_of_last_scan}</div>          
        </div>
      </div>
    </>
  );
};

const convertToRelativeTime = (timestamp) => {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
  const timeDifference = currentTime - timestamp; // Time difference in seconds

  // Convert time difference into readable format
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  if (timeDifference < secondsInMinute) {
    return `${timeDifference} seconds ago`;
  } else if (timeDifference < secondsInHour) {
    const minutes = Math.floor(timeDifference / secondsInMinute);
    return `${minutes} minutes ago`;
  } else if (timeDifference < secondsInDay) {
    const hours = Math.floor(timeDifference / secondsInHour);
    return `${hours} hours ago`;
  } else {
    const days = Math.floor(timeDifference / secondsInDay);
    return `${days} days ago`;
  }
};
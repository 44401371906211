import React, { useState } from 'react';
import { useAuth } from '../context/contextAuth';
import { useNavigate, Link } from 'react-router-dom';

import Loader3 from './components/Loaders/Loader-3/Loader-3';
import LogoImg from './asset/img/logo/Save-Logo-Blue-2.webp';

export default function SignIn(){
  const { loadingAuth, getSignin } = useAuth();
  const [eyeBtn, setEyeBtn] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const response = await getSignin({username, password});
      if (response.error) {
        setError(response.error); // Display backend error message
      } else {
        navigate('/mfa');
      }
    } catch (error) {
      setError(error.message);
    }
  };
  
  const toggleEyeBtn = () => { 
    setEyeBtn(!eyeBtn);
  }

  const eyeIcon = eyeBtn ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill';
  const inputType = eyeBtn ? 'password' : 'text';

  return (
    <>
      <div className="sign-wrapper">
        {loadingAuth ? (<Loader3 />):(
          <div className="position-absolute top-50 start-50 translate-middle">
            <div className='bg-white py-5 pb-5 rounded-5 px-4 px-lg-5 signin-form'>
              <div className="logo pb-4 mb-2">
                <img 
                  className="mx-auto d-block" 
                  src={LogoImg} 
                  alt="Logo"
                  style={{ height: 50 }}
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-4">
                  <input 
                    type="text" 
                    className="form-control rounded-3"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder=" " required
                  />
                  <label htmlFor="name" className="text-secondary">Username</label>
                </div>
                <div className="form-group mb-4">
                  <input 
                    type={inputType} 
                    className="form-control rounded-3"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder=" " required
                  />
                  <label htmlFor="name" className="text-secondary">Password</label>
                  <div className='position-absolute top-50 end-0 translate-middle-y p-3 z-3' onClick={toggleEyeBtn}>
                    <i className={eyeIcon}/>
                  </div>
                </div>
                {/* Remember Me */}
                <div className="form-check mb-2 d-flex justify-content-center align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="rememberMe"
                    checked={isRememberMeChecked}
                    onChange={(e) => setIsRememberMeChecked(e.target.checked)}
                    style={{ marginRight: 10 }}
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Remember Me
                  </label>
                </div>
                <div className='text-center'>
                  {error && <div className="error text-danger mb-2 fs-6">{error}</div>}
                  <button className="btn border-0 w-100 rounded-3" type="submit">Submit</button>
                  <div className='mt-3 bottom-text'>
                    Not a member yet?
                    <Link to="/signup">
                      &nbsp;<b className='bottom-text-highlight'>Sign Up</b>
                    </Link>
                  </div>
                </div>                
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
import React, { useContext, useRef, useEffect, useState } from 'react';
import { useUser } from '../../context/contextUser';
import { fetchUserLogs } from '../../api/users';

function BellIcon() {
  const { userData } = useUser();
  const [showNotify, setShowNotify] = useState(false);
  const dropdownRef = useRef(null);
  const [notificationCount, setNotificationCount] = useState('+9');

  const handleClick = () => {
    setShowNotify(!showNotify);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowNotify(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="dropdown">
      <div>
        <button className="btn btn-secondary bg-transparent border-0"
          type="button" onClick={handleClick}>
          <i className="bi bi-bell-fill bellIcon" />
          <span className="position-absolute top-0 start-100 
            translate-middle badge rounded-pill text-bg-secondary"
            style={{ marginTop: 4, marginLeft: -6 }}>
            {notificationCount}
            <span className="visually-hidden">unread messages</span>
          </span>
        </button>
      </div>
      {showNotify && <ShowNotifications2 userData={userData} />}
    </div>
  );
}
export default BellIcon;

const ShowNotifications = () => {
  const { userData } = useUser();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const logs = await fetchUserLogs(userData?.id);
        setNotifications(logs);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (userData) {
      fetchNotifications();
    }
  }, [userData]);

  const widgetCardBGColor = '#17234D ';
  const widgetAreaStyleColor = 'rgb(52 66 102)';

  return (
    <div
      className="row position-absolute rounded-5 shadow-lg"
      style={{ width: 360, height: 440,
        right: -160, top: 40,
        background: widgetCardBGColor,
        transition: 'all 0.2s',
      }}>
      <div className="d-flex justify-content-center align-items-center 
        py-3 text-center text-white rounded-5 position-absolute"
        style={{ height: 60 }}>
        <span style={{ fontSize: '22px', fontWeight: 300 }}>
          Notifications
        </span>
      </div>
      <div className='w-100 d-flex flex-column externalLinkRowRapper 
        scrollbar-externalLinksPopup p-2 rounded-5 m-1' 
        style={{ marginTop: 70, height: 360, width: 360,}}>
        <div className='py-4'></div>
        {notifications.map((notification, index) => (
          <div key={index} className='rounded-4' style={{marginTop: 0, height: 'auto' }}>
            <div className="px-4">
              <div className="text-white py-2">
                <small style={{ fontSize: '11px' }}>{new Date(notification.timestamp).toLocaleString()}</small>
                <h6>{notification.title}</h6>
                <p style={{ fontSize: '12px' }}>{notification.message}</p>
              </div>
            </div>
          </div>
        ))}        
      </div>       
    </div>
  );
};

const ShowNotifications2 = () => {
  const { userData } = useUser();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const logs = await fetchUserLogs(userData?.id);
        setNotifications(logs);
        console.log(notifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (userData) {
      fetchNotifications();
    }
  }, [userData]);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center 
        py-3 text-center text-white rounded-5 position-absolute bg-white z-2"
        style={{  width: 360, height: 60,
          right: -172, top: 40,
          transition: 'all 0.2s',
        }}>
        <span style={{ fontSize: '22px', fontWeight: 300 }} className='text-dark'>
          Notifications
        </span>
      </div>
      <div
        className="row position-absolute rounded-5 shadow-lg bg-white scrollbar-externalLinksPopup"
        style={{ width: 360, 
          right: -160, top: 40,
          transition: 'all 0.2s', 
          height: 440,
          border: 'solid 4px #fff',
        }}>    
        <div className='py-4'></div>    
        <div className='w-100 d-flex flex-column p-2 rounded-5 m-1'>          
          {notifications.map((notification, index) => (
            <div key={index} className='rounded-4 mb-3 shadow border border-1'>
              <div className="px-4">
                <div className="">
                  <small style={{ fontSize: '11px' }}>{new Date(notification.timestamp).toLocaleString()}</small>
                  <h6>{notification.title}</h6>
                  <p style={{ fontSize: '12px' }}>{notification.message}</p>
                </div>
              </div>            
            </div>          
          ))}        
        </div>       
      </div>
    </>
  );
};
import apiConfig from './api-config';
const api_base_url = apiConfig.api_base_url;
const api_key = apiConfig.api_key;

// Basic Access
export const signin = async (credentials) => {
  const { username, password } = credentials;

  if (!username) {
    throw new Error('Email is required');
  }
  if (!password) {
    throw new Error('Password is required');
  }
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/signin`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials),
    });
    
    if (!response.ok) {
      // Log the response for debugging
      console.error('Response:', response);
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to sign up');
    }
    
    return response.json();
  } catch (error) {
    throw error; // Re-throw the error to handle it in the calling function
  }
};
export const signup = async (userData) => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/signup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      // Log the response for debugging
      console.error('Response:', response);
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to sign up');
    }

    return response.json();
  } catch (error) {
    console.error('Error during signup:', error.message || error);
    throw error;
  }
};
export const verify2FACode = async (userId, code) => {
  const token = sessionStorage.getItem('authToken2FA');
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/verifyMFA`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ code }),  // Send as an object
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to verify 2FA');
    }
    return response.json();
  } catch (error) {
    console.error('Error verifying 2FA:', error);
    throw error;
  }
};
// Update Features
export const updateUserdata = async (userId, newUserData) => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/updateUserData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: JSON.stringify(newUserData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to update current data');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating current data:', error);
    throw error;
  }
};
export const uploadProfilePicture = async (userId, profilePictureFile) => {
  if (profilePictureFile) {
    const formData = new FormData();
    formData.append('profilePicture', profilePictureFile);

    await fetch(`${api_base_url}/api/${api_key}/users/${userId}/updateUserPic`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: formData
    });
  }
};
// Get Features
export const getUserProfile = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {throw new Error('No token found');}

    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getProfile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === 'Token expired') {
        alert('Your session has expired. Please log in again.');
      } else {
        throw new Error(errorData.error || 'Failed to fetch user profile');
      }
    }
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};
export const getImageUrl = async (userId, imagePath) => {
  if (!userId || !imagePath) {
    throw new Error("User ID and image path are required");
  }

  // Construct the base URL
  const url = `${api_base_url}/api/${api_key}/users/${userId}/images${imagePath}`;

  return url;
};
// Get Logs
export const fetchUserLogs = async (userId) => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getLogs`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user logs');
    }

    const logs = await response.json();
    console.log('User Logs:', logs);
    return logs;
  } catch (error) {
    console.error('Error fetching user logs:', error);
    throw error;
  }
};
// Testing
export const test = async (userId) => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/test`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user url details:', error);
    throw error;
  }
};


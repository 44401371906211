import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './App-fx.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-circular-progressbar/dist/styles.css';

import Main from './modules/Main';


function App() {
  
  return (
    <BrowserRouter
      future={{ 
        v7_startTransition: true, 
        v7_relativeSplatPath: true 
      }}>
      <Main />
    </BrowserRouter>
  );
}

export default App;
import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import styled, { keyframes } from 'styled-components';
import { useAuth } from './contextAuth';
import { useUser } from './contextUser';
import { chatBot } from '../api/api-post';


export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { userId } = useAuth();
  const { chatMessages, setChatMessages } = useUser();
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [typingMessage, setTypingMessage] = useState('');
  const [mount, setMount] = useState(false);
  const messagesEndRef = useRef(null);
	const [isTyping, setIsTyping] = useState(false); // Track if typing is ongoing
  
  const handleChat = async () => {
    if (!prompt || isTyping) {
      if (isTyping) {
        alert('Please wait for the bot to finish its response before typing a new message.');
      }
      return;
    }

    setLoading(true);
    setIsTyping(true); // Set typing state to true
    setChatMessages((prev) => [...prev, { type: 'user', text: prompt }]);
    setPrompt(''); // Clear the input field

    try {
      const response = await chatBot(prompt);
      const generatedText = response?.candidates?.[0]?.content?.parts?.[0]?.text || 'Sorry, no response available.';
      setTypingMessage(''); // Clear any previous typing effect

      let index = 0;
      const typingInterval = setInterval(() => {
        setTypingMessage((prev) => prev + generatedText[index]);
        index += 1;
        if (index >= generatedText.length) {
          clearInterval(typingInterval);
          setTypingMessage(''); // Ensure it's cleared after typing
          setChatMessages((prev) => [...prev, { type: 'bot', text: generatedText }]);
          setIsTyping(false); // Mark typing as finished
        }
      }, 10);

      // Ensure the interval is cleared on component unmount
      return () => clearInterval(typingInterval);

    } catch (error) {
      console.error('Error:', error);
      setChatMessages((prev) => [...prev, { type: 'bot', text: 'Sorry, an error occurred.' }]);
      setIsTyping(false); // Reset typing state even on error
    } finally {
      setLoading(false);
    }
  };
  

  const props = {
		userId,
		chatMessages,
		setChatMessages,
		prompt,
		setPrompt,
    mount, setMount,
		loading,
		setLoading,
		typingMessage,
		setTypingMessage,
		messagesEndRef,
		handleChat
	}

  return (
    <ChatContext.Provider
      value={props}>{children}
    </ChatContext.Provider>
  );
};


export const useChat = () => {
  return useContext(ChatContext);
};